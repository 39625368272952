import { createStore as reduxCreateStore } from "redux";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Toastify from "toastify-js";
import universalEligibilityQuestions from "../components/universal-enrollment-platform/components/eligibility-questions/questions";

const reducer = (state, action) => {
  if (action.type == "ADD_FOR_COMPARISON") {
    const itemsForComparison = state.itemsForComparison;

    if (itemsForComparison.size == 3) {
      Toastify({
        text: "You can compare up to 3 plans",
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "bottom",
        offset: { x: 0, y: 72 },
        position: "center",
        backgroundColor: "#D33F49",
        stopOnFocus: true,
        className: "notification",
      }).showToast();

      return {
        ...state,
      };
    }

    itemsForComparison.add(action.payload);

    return {
      ...state,
      itemsForComparison: new Set(Array.from(itemsForComparison)),
    };
  }

  if (action.type == "REMOVE_FROM_COMPARISON") {
    const itemsForComparison = state.itemsForComparison;
    itemsForComparison.delete(action.payload);

    return {
      ...state,
      itemsForComparison: new Set(Array.from(itemsForComparison)),
    };
  }

  if (action.type == "CLEAR_COMPARISON_LIST") {
    return {
      ...state,
      itemsForComparison: new Set(),
    };
  }

  if (action.type === `ADD_FILTER` || action.type === `REMOVE_FILTER`) {
    const { categoryKey, item, value, searchValue, doctorName } =
      action.payload;
    const filters = state.filters || {};

    if (!filters[categoryKey][item.type]) filters[categoryKey][item.type] = {};

    if (item.type == "CHECKBOX") {
      if (!filters[categoryKey][item.type][item.attribute])
        filters[categoryKey][item.type][item.attribute] = new Set();

      const currentSet: Set<String> =
        filters[categoryKey][item.type][item.attribute];

      if (action.type === `ADD_FILTER`) {
        currentSet.add(value);
      } else {
        currentSet.delete(value);
      }

      filters[categoryKey][item.type][item.attribute] = currentSet;
      return {
        ...state,
        ...{ filters },
      };
    } else if (item.type == "MIN_MAX_SLIDER") {
      filters[categoryKey][item.type][item.attribute] = {
        min: value[0],
        max: value[1],
      };

      return {
        ...state,
        ...{ filters },
      };
    } else if (item.type === "SEARCH") {
      filters[categoryKey][item.type][item.attribute] = value;

      return {
        ...state,
        ...{ filters },
      };
    } else if (item.type == "DOCTOR") {
      if (action.type === "ADD_FILTER") {
        filters[categoryKey][item.type][item.attribute] = value;
        filters[categoryKey][item.type]["search_value"] = searchValue;
        filters[categoryKey][item.type]["doctor_name"] = doctorName;
      } else {
        filters[categoryKey][item.type][item.attribute] = undefined;
        filters[categoryKey][item.type]["search_value"] = undefined;
        filters[categoryKey][item.type]["doctor_name"] = undefined;
      }

      return {
        ...state,
        ...{ filters },
      };
    }
  }

  if (action.type == "SORT") {
    return {
      ...state,
      ...{ sort: action.payload },
    };
  }

  if (action.type == "FETCH_QUOTES") {
    return {
      ...state,
    };
  }

  if (action.type == "SET_PARAMS") {
    return {
      ...state,
      ...{ params: action.payload },
    };
  }

  if (action.type == "SET_ADDITIONAL_INFO") {
    state.enrollment.nationalGeneral.additionalInfo[action.payload.key] =
      action.payload.value;
    return {
      ...state,
    };
  }
  if (action.type == "SET_APPLICANTS") {
    return {
      ...state,
      applicants: action.payload,
    };
  }

  if (action.type == "SET_APPLICATIONS") {
    return {
      ...state,
      applicants: {
        date_of_birth: action.payload.date_of_birth,
        main_applicant: action.payload.main_applicant,
        spouse: action.payload.spouse,
        dependents: action.payload.dependents,
      },
      enrollment: {
        nationalGeneral: {
          ...state.enrollment.nationalGeneral,
          plans: action.payload.plans,
          monthlyValue: action.payload.monthlyValue,
          fees: action.payload.fees,
          feesRaw: action.payload.feesRaw,
          applications: action.payload.applications.map((a) => ({
            ...a,
            applicationId: uuidv4(),
          })),
        },
      },
    };
  }

  if (action.type == "ANSWER_ELIGIBILITY_QUESTION") {
    state.enrollment.nationalGeneral.applications[
      action.payload.application_index
    ].eligibilityQuestions[action.payload.index]["answer"] =
      action.payload.answer;

    return {
      ...state,
      enrollment: { ...state.enrollment },
    };
  }

  if (action.type == "START_NATIONAL_GENERAL_ENROLLMENT") {
    return {
      ...state,
    };
  }

  if (action.type == "SET_TERMS") {
    state.enrollment.nationalGeneral = {
      ...state.enrollment.nationalGeneral,
      availableTerms: action.payload,
    };

    return {
      ...state,
    };
  }

  if (action.type == "UPDATE_CART_VALUES") {
    const fees = [];
    const feesRaw = action.payload.fees;

    if (action.payload.fees && action.payload.fees.ONE_TIME) {
      fees.push({
        label: "One time fees",
        value: action.payload.fees.ONE_TIME.total,
      });
    }

    if (action.payload.fees && action.payload.fees.MONTHLY) {
      fees.push({
        label: "Monthly fees",
        value: action.payload.fees.MONTHLY.total,
      });
    }

    return {
      ...state,
      specialRates: action.payload.specialRates
        ? action.payload.specialRates
        : state.specialRates,
      enrollment: {
        ...state.enrollment,
        nationalGeneral: {
          ...state.enrollment.nationalGeneral,
          plans: action.payload.plans,
          monthlyValue: action.payload.plans.reduce((a, b) => {
            return a + b.premium.value;
          }, 0),
          fees: fees,
          feesRaw,
        },
      },
    };
  }

  if (action.type == "UPDATE_SPECIAL_RATES") {
    return {
      ...state,
      specialRates: {
        ...action.payload,
      },
    };
  }

  if (action.type == "UPDATE_ASSOCIATION_FEE") {
    return {
      ...state,
      associationFee: action.payload.target.checked,
    };
  }

  if (action.type == "ANSWER_ELIGIBILITY_QUESTION_UNIVERSAL") {
    state.enrollment.universal.applications[0].questions[
      action.payload.application_index
    ].questions[action.payload.index]["answer"] = action.payload.answer;

    return {
      ...state,
      enrollment: { ...state.enrollment },
    };
  }

  if (action.type == "SET_APPLICATIONS_UNIVERSAL") {
    return {
      ...state,
      applicants: {
        date_of_birth: action.payload.date_of_birth,
        main_applicant: action.payload.main_applicant,
        spouse: action.payload.spouse,
        dependents: action.payload.dependents,
      },

      enrollment: {
        ...state.enrollment,
        universal: {
          ...state.enrollment.universal,
          plans: action.payload.plans,
          monthlyValue: action.payload.monthlyValue,
          applicants: {
            main_applicant: action.payload.main_applicant,
            spouse: action.payload.spouse,
            dependents: action.payload.dependents,
          },
          applications: [
            {
              questions: universalEligibilityQuestions,
              applicationId: uuidv4(),
            },
          ],
        },
      },
    };
  }

  if (action.type == "SET_ADDITIONAL_INFO_UNIVERSAL") {
    state.enrollment.universal.additionalInfo[action.payload.key] =
      action.payload.value;
    return {
      ...state,
    };
  }

  if (action.type == "SET_SELECTED_AGENT") {
    return {
      ...state,
      selectedAgent: action.payload,
    };
  }

  if (action.type == "SET_RESULTS") {
    return {
      ...state,
      results: action.payload,
    };
  }
  return state;
};

const initialState = (siteMetadata) => {
  return {
    selectedAgent: null,
    itemsForComparison: new Set(),
    baseUrl: siteMetadata?.baseUrl,
    agentMetadata: siteMetadata?.site?.siteMetadata?.agent,
    facets: [],
    results: {},
    params: {},
    quote_id: "",
    enrollment: {
      nationalGeneral: {
        planIds: [],
        applications: [],
        additionalInfo: {
          height: 0,
          spouse: {},
          dependents: [],
          effectiveDate: moment().add(1, "days"),
          firstPaymentDate: moment(),
        },
      },
      universal: {
        planIds: [],
        applications: [],
        applicants: {
          main_applicant: {},
          spouse: {},
          dependents: [],
        },
        additionalInfo: {
          height: 0,
          spouse: {},
          dependents: [],
          effectiveDate: moment().add(1, "days"),
          firstPaymentDate: moment(),
        },
      },
    },

    sort: {
      by: "Premium",
      order: "asc",
    },
    filters: {
      ALL: {},
      MARKETPLACE: {},
      NATIONAL_GENERAL: {},
      ONE_SHARE: {},
      UNITED_HEALTHCARE: {},
      UNIVERSAL: {},
      ANCILLARY: {},
      SHORT_TERM: {},
      MAJOR_MEDICAL: {},
      HEALTH_SHARE: {},
      VISION: {},
      DENTAL: {},
    },
  };
};

const createStore = (siteMetadata) => {
  try {
    if (window) {
      return reduxCreateStore(
        reducer,
        initialState(siteMetadata),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      );
    } else {
      return reduxCreateStore(reducer, initialState(siteMetadata));
    }
  } catch (e) {
    return reduxCreateStore(reducer, initialState(siteMetadata));
  }
};

export default createStore;
