import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Provider } from "react-redux";

import createStore from "./src/state/createStore";
import "antd/dist/antd.css";
import "./src/styles/antd/global.scss";
import "./src/styles/global.scss";
import "./src/styles/typography/global.scss";
import "./src/styles/headings/global.scss";
import "./src/styles/nav/global.scss";
import "./src/styles/antd/global.scss";
import "./src/styles/utils/global.scss";
import "toastify-js/src/toastify.css";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { Helmet } from "react-helmet";

Sentry.init({
  dsn: "https://16655732aa9f4aa4ad116652d23856c2@o487073.ingest.sentry.io/5945204",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: window.location.hostname,
});

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        {window.location.host === "apollo.myaspirequotes.com" ? (
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js.hs-scripts.com/22419384.js"
          ></script>
        ) : null}
      </Helmet>
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                agent {
                  assets {
                    image
                  }
                  baseUrl
                  id
                  name
                  phone
                  whatsapp
                  email
                  website
                  title
                  imageSrc
                  calendly
                  bio
                }
              }
            }
          }
        `}
        render={(data) => {
          return (
            <Provider
              store={createStore({
                ...data,
                baseUrl: data?.site?.siteMetadata?.agent?.baseUrl,
              })}
            >
              {element}
            </Provider>
          );

          // https://api.dev.qa.apolloquotes.com
          // https://api.enrollment.myaspirequotes.qa.apolloquotes.com
          // https://temp.enrollment.qa.apolloquotes.com
          // https://api.test.natgen.qa.apolloquotes.com
        }}
      />
    </>
  );
};
